<template>
  <div id="edit-xml-config" class="vap-page">
  <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div id="edit-xml-config-header" class="vap-page-header">
      <h1>{{ currentConfig.name }}</h1>
      <div class="btns-container">
        <VasionButton
          id="cancel-button"
          name="cancel-button"
          classProp="secondary"
          @vasionButtonClicked="cancel()"
        >
          Cancel
        </VasionButton>

        <VasionButton
          id="save-button"
          name="save-button"
          class="last-btn"
          classProp="primary"
          :isDisabled="!isValid || !isDirty"
          @vasionButtonClicked="save()"
        >
          Save
        </VasionButton>
      </div>
    </div>

    <div id="main-section" class="vap-page-content">
      <div id="config-details">
        <div class="row">
          <VasionInput
            id="xml-config-name"
            v-model="currentConfig.name"
            class="row-field"
            title="CONFIGURATION NAME"
            inputType="top-white"
            name="xml-config-name"
            placeholder="Enter Name..."
            :required="true"
            :width="'396'"
            @input="markDirty()"
          />
          <VasionDropList
            v-slot="slotItem"
            v-model="currentConfig.schema"
            class="row-field"
            :dataArray="xmlSchemaList"
            width="396"
            title="XML SCHEMA"
            placeholder="Select Schema..."
            type="plain-list"
            displayName="schemaName"
            valueName="schemaId"
            :isDisabled="isEdit"
            :required="true"
            @input="xmlSchemaChanged"
          >
            {{ slotItem.item.schemaName }}
          </VasionDropList>

          <VasionButton
            id="view-schema-sample-button"
            class="inline-button"
            :classProp="'primary'"
            :isDisabled="!currentConfig.schema"
            @vasionButtonClicked="toggleShowSampleXmlSchemaDialog"
          >
            View Sample
          </VasionButton>
        </div>
        
        <div class="row">
          <VasionDropList
            v-slot="slotItem"
            v-model="currentConfig.object"
            class="row-field"
            :dataArray="attributeFormList"
            width="396"
            :isDisabled="isEdit"
            :title="`${$formsLabel.toUpperCase()}`"
            :placeholder="`Select ${$formsLabel}...`"
            :required="true"
            type="plain-list"
            displayName="name"
            valueName="value"
            @input="objectChanged()"
          >
            {{ slotItem.item.name }}
          </VasionDropList>
          
          <VasionInput
            id="xml-file"
            v-model="currentConfig.path"
            class="row-field browse-input"
            title="XML FILE"
            inputType="top-white"
            name="xml-file"
            placeholder="Select File..."
            :isDisabled="true"
            :required="true"
            :width="'396'"
          />
          <VasionButton
            id="sample-file-folder-button"
            class="inline-button"
            :classProp="'primary'"
            :isDisabled="!currentConfig.schema"
            @vasionButtonClicked="toggleBrowseForShowXmlFileDialog()"
          >
            Browse
          </VasionButton>
        </div>
        
        <div v-show="displayTable">
          <VasionEditableTable
            ref="fieldMappingTable"
            class="vasion-html-table-default paddingRight"
            :headerColumns="headerColumns"
            :supportSorting="false"
            :tableRows="tableRows"
            @cellValueChanged="cellValueChanged"
          />
        </div>
      </div>
    </div>

    <VasionGeneralModal
      v-if="currentConfig.schema"
      :rejectButtonText="'CANCEL'"
      :modalType="'slot'"
      :showConfirmButton="false"
      :sync="showSampleXml"
      @noButtonClick="toggleShowSampleXmlSchemaDialog"
    >
      <span class="vasion-page-title">XML Schema: {{ currentConfig.schema.schemaName }} </span>
      <pre class="schema-sample">{{ xmlSchemaSampleFormatted }}</pre>
    </VasionGeneralModal>

    <VasionFileUploadDialog
      :serverOptions="serverOptions"
      :show.sync="showXmlFileDialog"
      :acceptedFileTypes="['.xml']"
      :filePondLabel="filePondLabel"
      @allFilesUploaded="toggleBrowseForShowXmlFileDialog()"
    />

    <VasionSnackbar
      id="xml-config-snack"
      :showSnackbarBool.sync="showVasionSnackbar"
      :snackbarImage="vasionSnackbarImage"
      :snackbarSubTitle="vasionSnackbarSubTitle"
      :snackbarTitle="vasionSnackbarTitle"
    />

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="cancelLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>
  </div>
</template>

<script>
import { toBase64 } from '@/store/helperModules/storage.module'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import Loading from 'vue-loading-overlay';

export default {
  name: 'EditXmlConfiguration',
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      allHeaderColumns: [
        {title: 'Field', sortable: false, type: -1},
        {title: 'Object Data', sortable: false, type: 2},
        {title: 'Appending Data', sortable: false, type: 2},
        {title: 'Is Number', sortable: false, type: 9},
      ],
      currentConfig: {
        fields: [],
        id: 0,
        name: '',
        object: null,
        schema: null,
        path: '',
      },
      isDirty: false,
      isEdit: false,
      isLoading: true,
      isValid: false,
      filePondLabel: `<span class="filepond-drag">Drag XML File Here</span>
                <br/><br/>
                <span class="filepond-label-action">or click to upload your XML File</span>`,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      serverOptions: {
        process: this.processHandler,
      },
      showLeaveDialog: false,
      showSampleXml: false,
      showVasionSnackbar: false,
      tableRows: [],
      uploadedCSVFileString: '',
      vasionSnackbarImage: false,
      vasionSnackbarSubTitle: '',
      vasionSnackbarTitle: '',
      xmlFieldList: [],
      showXmlFileDialog: false,
      xmlSchemaList: [],
    }
  },
  components: {
    Loading,
  },
  computed: {
    attributeFormList() {
      return this.$store.state.attributeForm.forms?.map((f) => {
        return {
          name: f.name,
          value: f.value,
        }
      })
    },
    attributeFormListFields() {
      return this.$store.state.common.indexFields
    },
    displayTable() {
      return this.currentConfig.schema && this.attributeFormListFields.length > 0 && this.xmlFieldList?.length > 0
    },
    headerColumns() {
      return this.hasIsNumberColumn ? this.allHeaderColumns : this.allHeaderColumns.slice(0, -1)
    },
    hasIsNumberColumn() {
      return this.currentConfig.schema?.schemaName === 'Objectif Lune'
    },
    xmlSchemaSampleFormatted() {
      return this.currentConfig.schema.schemaSample.replaceAll('><', '>\n<')
    },
  },
  async created() {
    if (!this.attributeFormList || this.attributeFormList.length === 0) {
      await this.$store.dispatch('attributeForm/getForms')
    }
    this.xmlSchemaList = await this.$store.dispatch('admin/getXmlSchemaList')
    if (this.$store.state.admin.editXmlConfig) {
      this.currentConfig = this.$store.state.admin.editXmlConfig
    }

    this.isEdit = this.currentConfig.id > 0
    if (this.isEdit) {
      //the data that we get from the backend doesn't quite match our data format for editing
      this.currentConfig.schema = this.xmlSchemaList.find(s => s.schemaId === this.currentConfig.schemaId)
      this.currentConfig.object = { name: this.currentConfig.objectName, value: this.currentConfig.objectId }
      //this prefills the xmlFieldList from values already in use
      const savedFieldList = new Set()
      savedFieldList.add('[None]')
      this.currentConfig.fields.forEach(field => {
        if (field.parameter) {
          savedFieldList.add(field.parameter)
        }
        if (field.appendingParameter) {
          savedFieldList.add(field.appendingParameter)
        }
      })
      this.xmlFieldList = Array.from(savedFieldList).map(( field, index) => { return { name: field, value: index} })
    }
    this.loadObject()
    this.isLoading = false
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'FormsXMLFields' })
    },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    cellValueChanged({rowData, columnIndex}) {
      if ((columnIndex === 1 || columnIndex === 2) && rowData.Cells[columnIndex].cellData.name === '[None]') {
        rowData.Cells[columnIndex].cellData = null
      }
      this.markDirty()
    },
    checkIfValid() {
      this.isValid = true

      if (!this.currentConfig.name || this.currentConfig.name.trim() === '') {
        this.isValid = false
      }

      if (!this.currentConfig.schema || !this.currentConfig.object || !this.currentConfig.path) {
          this.isValid = false
      }

      let hasField = false
      this.tableRows.forEach(rowData => {
        if (rowData.Cells[1].cellData?.name || rowData.Cells[2].cellData?.name){
          hasField = true
        }
      })
      if (!hasField)
        this.isValid = false
    },
    createTable() {
      if (!this.displayTable) {
        return
      }
      this.tableRows = []
      this.attributeFormListFields.forEach((field, index) => {
        let obj = {}
        obj.isSelected = false
        obj.Cells = []

        let fieldMapping = null
        let parameter = null
        let appParameter = null
        if (this.currentConfig.fields && this.currentConfig.fields.length > 0 && this.xmlFieldList) {
          fieldMapping = this.currentConfig.fields.find(fieldMapping => fieldMapping.indexField === field.fieldID)
          if (fieldMapping) {
            parameter = this.xmlFieldList.find(xmlField => xmlField.name === fieldMapping.parameter)
            appParameter = this.xmlFieldList.find(xmlField => xmlField.name === fieldMapping.appendingParameter)
          }
        }
        obj.Cells[0] = {
          cellData: field.name,
        }
        obj.Cells[1] = {
          cellData: parameter,
          dropdownValues: this.xmlFieldList && this.xmlFieldList.length !== 0 ? this.xmlFieldList : [{name: '*No fields*', value: -1}],
          canEdit: true,
          fieldName: '',
          subType: 'plain-list'
        }
        obj.Cells[2] = {
          cellData: appParameter,
          dropdownValues: this.xmlFieldList && this.xmlFieldList.length !== 0 ? this.xmlFieldList : [{name: '*No fields*', value: -1}],
          canEdit: true,
          fieldName: '',
          subType: 'plain-list'
        }
        if (this.hasIsNumberColumn) {
          obj.Cells[3] = {
            cellData: fieldMapping ? fieldMapping.isNumber : false,
            isDisabled: false,
          }
        }
        this.tableRows[index] = obj
      })
    },
    doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    async loadObject() {
      if (this.currentConfig.object?.value > 0) {
        await this.$store.dispatch('common/getIndexFieldsForForm', this.currentConfig.object.value)
      } else {
        await this.$store.dispatch('common/resetIndexFields')
      }
      this.createTable()
    },
    markDirty() {
      this.isDirty = true
      this.checkIfValid()  
    },
    objectChanged() {
      this.markDirty()
      this.currentConfig.fields = []
      this.loadObject()
    },
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      if (file?.name.endsWith('.xml')) {
        this.uploadedCSVFileString = await toBase64(file)
        const base64String = this.uploadedCSVFileString.split('base64,')[1]
        if (!base64String){
          error(true)
          this.vasionSnackbarTitle = 'Error'
          this.vasionSnackbarSubTitle = 'Empty XML can not be used'
          this.showVasionSnackbar = true
          return
        }
        const payload = {schemaId: this.currentConfig.schema.schemaId, fileData: base64String}
        const schemaResult = await this.$store.dispatch('admin/loadImportXmlSchemaFields', payload)
        if (!Array.isArray(schemaResult)) {
          error(true)
          this.vasionSnackbarTitle = 'Error'
          this.vasionSnackbarSubTitle = schemaResult
          this.vasionSnackbarImage = false
          this.showVasionSnackbar = true
          return
        }

        schemaResult.unshift('[None]')
        this.xmlFieldList = schemaResult.map(( field, index) => { return { name: field, value: index} })
        progress(true, 0, 1024)
        load(file?.name)
        this.currentConfig.path = file?.name
        this.markDirty()
        this.currentConfig.fields = []
        this.createTable()
      } else {
        error(true)
        this.vasionSnackbarTitle = 'Error'
        this.vasionSnackbarSubTitle = "Can only upload files that end in \".xml\"."
        this.vasionSnackbarImage = false
        this.showVasionSnackbar = true
      }
      return {
        abort: () => {
          abort();
        },
      };
    },
    async save() {
      this.isLoading = true
      let fieldMappings = []
      this.tableRows.forEach((row, index) => {
        const objectField = this.attributeFormListFields[index]
        fieldMappings[index] = {
          indexField: objectField.fieldID,
          parameter: row.Cells[1].cellData?.name,
          appendingParameter: row.Cells[2].cellData?.name,
          isNumber: row.Cells[3] ? row.Cells[3].cellData : false
        }
      })

      let payload = {
        id: this.currentConfig.id,
        name: this.currentConfig.name,
        objectId: this.currentConfig.object.value,
        schemaId: this.currentConfig.schema.schemaId,
        path: this.currentConfig.path,
        fields: fieldMappings,
      }
      
      if (await this.$store.dispatch('admin/saveXmlConfiguration', payload)) {
        this.isLoading = false
        this.vasionSnackbarTitle = 'Success'
        this.vasionSnackbarSubTitle = 'XML Configuration saved successfully'
        this.vasionSnackbarImage = true
        this.showVasionSnackbar = true
        this.isDirty = false
        setTimeout(() => {
          this.$router.push({ name: 'FormsXMLFields' })
        }, 1500)
      } else {
        this.isLoading = false
        this.vasionSnackbarTitle = 'Error'
        this.vasionSnackbarSubTitle = 'XML Configuration NOT saved'
        this.vasionSnackbarImage = false
        this.showVasionSnackbar = true
      }
    },
    toggleBrowseForShowXmlFileDialog() { this.showXmlFileDialog = !this.showXmlFileDialog },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    toggleShowSampleXmlSchemaDialog() { 
      if (this.currentConfig.schema) {
        this.showSampleXml = !this.showSampleXml
      }
    },
    xmlSchemaChanged() {
      this.markDirty()
      this.currentConfig.path = ''
      this.xmlFieldList = []
      this.currentConfig.fields = []
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';

  #edit-xml-config {
    #main-section {
      #config-details {
        .row {
          width: 100%;
          margin: 13px 0;
          display: flex;

          .row-field {
            margin: 0 14px 5px 0;
          }
        }
      }
    }
    
    .inline-button {
      padding-top: 17px;
    }

    .schema-sample {
      width: 40vw;
      min-width: 280px;
      margin-top: 10px;
    }
    
    ::v-deep .table-header {
      padding: 0px;
    }
    ::v-deep .vasion-html-table-default table td > div {
      padding: 0px;
    }
    .paddingRight {
      padding-right: 16px;
    }
  }
</style>
